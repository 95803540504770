@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200&family=Oxanium:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Big+Shoulders+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anta&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.body
{
  background-color: black;
}
.notosans
{
    font-family: 'Noto Sans', sans-serif;
    font-family: 'Oxanium', cursive;
}

.rajdhani
{
    font-family: 'Rajdhani', sans-serif;
}

.oswald
{
    font-family: 'Oswald', sans-serif;
}
.anta 
{
  font-family: "Anta", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.BigShoulders
{
    font-family: 'Big Shoulders Text', cursive;
}


.glowingButton
{
    -webkit-transform: translate(-0%, 0%);
            transform: translate(-0%, 0%);
    text-align: center;
    padding: 2px;
    padding-left: 2.5vmax;
    padding-right: 2.5vmax;
    line-height:  6vh;
    color: #bababa;
    background: none;
    font-size: 1.2vmax;
    text-transform: uppercase;
    text-decoration: none;
    font-style: "Source Sans Pro, sans-serif";
    box-sizing: border-box;
    background-size: 0%;
    background-size: 400%;
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
    border-radius: 5vw;
    border: 0.1vh solid #8a8a8a;
}

.button7 {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }

.glowingButton:hover{
    -webkit-animation: animate 8s linear infinite;
            animation: animate 8s linear infinite;
    cursor: pointer;
    color: transparent;
}
@-webkit-keyframes animate
{
    0%
    {
        background-position: 0%;
    }
    100%
    {
        background-position: 400%;
    }
}
@keyframes animate
{
    0%
    {
        background-position: 0%;
    }
    100%
    {
        background-position: 400%;
    }
}

.glowingButton::before
{
    content: '';
    text-align: center;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: -1;    
    background: linear-gradient(90deg, #03a9f4, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 30px;
    opacity: 0;
    transition: 0.5s;
}
.glowingButton:hover:before
{
    -webkit-filter: blur(20px);
            filter: blur(20px);
    opacity: 1;
    -webkit-animation: animate 8s linear infinite;
            animation: animate 8s linear infinite;
}

/* CSS */
.button62 {
  background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  font-size: 1vmax;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button62:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.button62:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}


/* CSS */
.button2 {
  background: #d1d1d1;
  color: black;
  width: 7vw;
  border: none;
  border-radius: 5px;
}

.button2 {
  background: #d1d1d1;
  color: black;
  width: 7vw;
  border: none;
  border-radius: 5px;
}
.glowingButton
{
    -webkit-transform: translate(-0%, 0%);
            transform: translate(-0%, 0%);
    text-align: center;
    padding: 0px;
    padding-left: 2vw;
    padding-right: 2vw;
    line-height:  6vh;
    color: #bababa;
    background: none;
    font-size: 1.5vh;
    text-transform: uppercase;
    text-decoration: none;
    font-style: "Source Sans Pro, sans-serif";
    box-sizing: border-box;
    background-size: 0%;
    background-size: 400%;
    -webkit-backdrop-filter: blur(30px);
            backdrop-filter: blur(30px);
    border-radius: 5vw;
    border: 0.1vh solid #8a8a8a;
}

.button7 {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }

.glowingButton:hover{
    -webkit-animation: animate 8s linear infinite;
            animation: animate 8s linear infinite;
    cursor: pointer;
    color: transparent;
}
@-webkit-keyframes animate
{
    0%
    {
        background-position: 0%;
    }
    100%
    {
        background-position: 400%;
    }
}
@keyframes animate
{
    0%
    {
        background-position: 0%;
    }
    100%
    {
        background-position: 400%;
    }
}

.glowingButton::before
{
    content: '';
    text-align: center;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(90deg, #03a9f4, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 30px;
    opacity: 0;
    transition: 0.5s;
}
.glowingButton:hover:before
{
    -webkit-filter: blur(20px);
            filter: blur(20px);
    opacity: 1;
    -webkit-animation: animate 8s linear infinite;
            animation: animate 8s linear infinite;
}
.LandingContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute; 
    width: 100vw;
}

.glass
{
    /* From https://css.glass */
    border-radius: 2px;
    opacity: .6;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.glass2
{
    /* From https://css.glass */
    border-radius: 2px;
    opacity: .6;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.glass3
{
    /* From https://css.glass */
    border-radius: 2px;
    opacity: .6;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Header
{
    margin-top: 11vmax;  
    font-size: 7vmax;
    color: white;
    text-align: center;
    opacity: 0;
}

.Subtitle
{
    text-align: center;
    font-size: 2vmax;
    color: #d9d9d9;
    margin-top: -3vmax;
    opacity: 0;
}

@media(orientation: landscape)
{

    @media(min-aspect-ratio: 14/10) and (max-aspect-ratio: 17/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 8.5vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 2.25vmax;
            color: #d9d9d9;
            margin-top: -3vmax;
        }
    }
    @media(min-aspect-ratio: 10.8/10) and (max-aspect-ratio: 13.999/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 9.5vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 2.75vmax;
            color: #d9d9d9;
            margin-top: -3vmax;
        }
    }

    @media(max-aspect-ratio: 10.799/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 10vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 3vmax;
            color: #d9d9d9;
            margin-top: -3vmax;
            width: 80%;
        }

        .Subtitle
        {
            color: #d9d9d9;
        }
    }
}

@media(orientation: portrait)
{
    .Subtitle
    {
        color: #d9d9d9;
    }

    @media(min-aspect-ratio: 9/10)
    {
        .Header
        {
            margin-top: 25vmax;  
            font-size: 11vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 3.5vmax;
            margin-top: -3vmax;
            width: 80%;
        }
    }
    @media(min-aspect-ratio: 7.5/10) and (max-aspect-ratio: 8.999/10)
    {

        .Header
        {
            margin-top: 25vmax;  
            font-size: 9.5vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 3vmax;
            margin-top: -3vmax;
            width: 80%;
        }
        
    }
    @media(min-aspect-ratio: 6.5/10) and (max-aspect-ratio: 7.49999/10)
    {
        .Header
        {
            margin-top: 25vmax;  
            font-size: 8vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 2.5vmax;
            margin-top: -3vmax;
            width: 80%;
        }
    }
    @media(min-aspect-ratio: 5.5/10) and (max-aspect-ratio: 6.49999/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 10vmax;
            width: 90%;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 4vmax;
            margin-top: -2vmax;
            width: 90%;
        }
    }
    @media(max-aspect-ratio: 5.5/10) and (min-aspect-ratio: 4.5/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 9.5vmax;
            width: 90%;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 3.25vmax;
            margin-top: -2vmax;
            width: 90%;
        }
    }
    @media(min-aspect-ratio: 3.5/10) and (max-aspect-ratio: 4.49999/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 9vmax;
            width: 90%;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 3vmax;
            margin-top: -2vmax;
            width: 90%;
        }
    }
}
.Text2Container
{
    position: absolute; 
    margin-top: 47vmax;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.VidMargin
{
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.FullContainer
{
    width: 100vw;
    height: 100vh; 
    position: absolute; 
    margin-top: 65vh;
}

.Text2
{
    font-size: 1.5vmax;
    color: white;
    width: 50vw;
    left: 50%;
}

.Vid:hover
{
    scale: 2.5;
    z-index: 100;
}

.Desc
{
    font-size: 1.2vmax;
    color: white;
    margin-left: 2vw;
    margin-top: -2vh;
}

.DescTitle
{
    font-size: 1.4vmax;
    color: yellow;
    margin-left: 2vw;
}

.Vid
{
    width: 40%;
    height: auto; 
}

@media(orientation: landscape)
{
    @media(min-aspect-ratio: 18/10) and (max-aspect-ratio: 19/10)
    {
        .FullContainer
        {
            width: 100vw;
            height: 100vh; 
            position: absolute; 
            margin-top: 85vh;
        }
    }
    @media(max-aspect-ratio: 17.9999 / 10) and (min-aspect-ratio: 16.500000001 / 10)
    {
        .FullContainer
        {
            width: 100vw;
            height: 100vh; 
            position: absolute; 
            margin-top: 95vh;
        }
    }

    @media(max-aspect-ratio: 16.5/10) and (min-aspect-ratio: 15.5 / 10)
    {
        .FullContainer
        {
            margin-top: 95vh;
        }
    }
    @media(max-aspect-ratio: 15.5/10) and (min-aspect-ratio: 14.5 / 10)
    {
        .FullContainer
        {
            margin-top: 105vh;
        }
    }
    @media(max-aspect-ratio: 14.5/10) and (min-aspect-ratio: 13.5 / 10)
    {
        .FullContainer
        {
            margin-top: 109vh;
        }
    }
    @media(max-aspect-ratio: 13.5/10) and (min-aspect-ratio: 12.5 / 10)
    {
        .FullContainer
        {
            margin-top: 114vh;
        }
    }
    @media(max-aspect-ratio: 12.5/10) and (min-aspect-ratio: 11.5 / 10)
    {
        .FullContainer
        {
            margin-top: 120vh;
        }
    }
    @media(max-aspect-ratio: 11.5/10) and (min-aspect-ratio: 10 / 10)
    {
        .FullContainer
        {
            margin-top: 125vh;
        }
    }
}

@media(orientation: portrait)
{
    @media(min-aspect-ratio: 9/10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 40vw;
        }
        .Vid:hover
        {
            scale: 2.25;
        }
        
        .Desc
        {
            font-size: 1.5vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }
    
    }
    @media(min-aspect-ratio: 8/10) and (max-aspect-ratio: 9 /10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 40vw;
        }
        .Vid:hover
        {
            scale: 2.25;
        }
        
        .Desc
        {
            font-size: 1.5vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }

        .VidMargin
        {
            margin-top: 2vh;
        }
    
    }
    @media(min-aspect-ratio: 7/10) and (max-aspect-ratio: 8 /10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 70vw;
        }
        .Vid:hover
        {
            scale: 1.4;
        }
        
        .Desc
        {
            font-size: 1.65vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }

        .VidMargin
        {
            margin-top: 2vh;
            flex-direction: column;
        }
    
    }

    @media(min-aspect-ratio: 6/10) and (max-aspect-ratio: 7 /10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 70vw;
        }
        .Vid:hover
        {
            scale: 1.4;
        }
        
        .Desc
        {
            font-size: 1.5vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }

        .VidMargin
        {
            margin-top: 2vh;
            flex-direction: column;
        }
    
    }
    
    @media(min-aspect-ratio: 5/10) and (max-aspect-ratio: 6 /10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 70vw;
        }
        .Vid:hover
        {
            scale: 1.4;
        }
        
        .Desc
        {
            font-size: 1.5vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }

        .VidMargin
        {
            margin-top: 2vh;
            flex-direction: column;
        }
    
    }
    @media(min-aspect-ratio: 0/10) and (max-aspect-ratio: 5 /10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 70vw;
        }
        .Vid:hover
        {
            scale: 1.4;
        }
        
        .Desc
        {
            font-size: 1.5vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }

        .VidMargin
        {
            margin-top: 2vh;
            flex-direction: column;
        }
    
    }
}

.Phase3Container
{
    width: 100vw;
    height: 100vh;
    position: absolute;
    margin-top: 375vh;
    z-index: 100;
}

.Phase3InnerContainer
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
}

.LinkedinRow
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50vw;
}

.Phase3Title
{
    z-index: 1000;
    font-size: 12vh;
    color: white;
}

.ConnectImg
{
    margin-left: 3vw;
    width: 7.5vw;
}
.Tilt
{
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    cursor: grab;
    -webkit-transform: TranslateZ(60px);
            transform: TranslateZ(60px);
}

.CardContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ImgStyle
{
    width: 12vw;
}

.Row
{
    margin-top: -12vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 3vmax;
    gap: 3vmax;
    justify-content: center;
}

.SectionContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 5vw;
    gap: 5vw;
    width: 100vw;
    height: 100vh;
    position: absolute;
    margin-top: 360vh;
    z-index: 100;
}

.Text
{
    font-size: 2vw;
    color: white;
    margin-left: 0.5vw;
}

@media(orientation: portrait)
{
    .Text
    {
        font-size: 4vw;
        color: white;
        margin-left: 0.5vw;
    }
    .ImgStyle
    {
        width: 20vw;
    }
    .Row
    {
        margin-top: -5vh;
    }

    .SectionContainer
    {
        margin-top: 350vh;
    }
}
