
.Phase3Container
{
    width: 100vw;
    height: 100vh;
    position: absolute;
    margin-top: 375vh;
    z-index: 100;
}

.Phase3InnerContainer
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
}

.LinkedinRow
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50vw;
}

.Phase3Title
{
    z-index: 1000;
    font-size: 12vh;
    color: white;
}

.ConnectImg
{
    margin-left: 3vw;
    width: 7.5vw;
}