.Text2Container
{
    position: absolute; 
    margin-top: 47vmax;
    left: 50%;
    transform: translateX(-50%);
}

.VidMargin
{
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.FullContainer
{
    width: 100vw;
    height: 100vh; 
    position: absolute; 
    margin-top: 65vh;
}

.Text2
{
    font-size: 1.5vmax;
    color: white;
    width: 50vw;
    left: 50%;
}

.Vid:hover
{
    scale: 2.5;
    z-index: 100;
}

.Desc
{
    font-size: 1.2vmax;
    color: white;
    margin-left: 2vw;
    margin-top: -2vh;
}

.DescTitle
{
    font-size: 1.4vmax;
    color: yellow;
    margin-left: 2vw;
}

.Vid
{
    width: 40%;
    height: auto; 
}

@media(orientation: landscape)
{
    @media(min-aspect-ratio: 18/10) and (max-aspect-ratio: 19/10)
    {
        .FullContainer
        {
            width: 100vw;
            height: 100vh; 
            position: absolute; 
            margin-top: 85vh;
        }
    }
    @media(max-aspect-ratio: 17.9999 / 10) and (min-aspect-ratio: 16.500000001 / 10)
    {
        .FullContainer
        {
            width: 100vw;
            height: 100vh; 
            position: absolute; 
            margin-top: 95vh;
        }
    }

    @media(max-aspect-ratio: 16.5/10) and (min-aspect-ratio: 15.5 / 10)
    {
        .FullContainer
        {
            margin-top: 95vh;
        }
    }
    @media(max-aspect-ratio: 15.5/10) and (min-aspect-ratio: 14.5 / 10)
    {
        .FullContainer
        {
            margin-top: 105vh;
        }
    }
    @media(max-aspect-ratio: 14.5/10) and (min-aspect-ratio: 13.5 / 10)
    {
        .FullContainer
        {
            margin-top: 109vh;
        }
    }
    @media(max-aspect-ratio: 13.5/10) and (min-aspect-ratio: 12.5 / 10)
    {
        .FullContainer
        {
            margin-top: 114vh;
        }
    }
    @media(max-aspect-ratio: 12.5/10) and (min-aspect-ratio: 11.5 / 10)
    {
        .FullContainer
        {
            margin-top: 120vh;
        }
    }
    @media(max-aspect-ratio: 11.5/10) and (min-aspect-ratio: 10 / 10)
    {
        .FullContainer
        {
            margin-top: 125vh;
        }
    }
}

@media(orientation: portrait)
{
    @media(min-aspect-ratio: 9/10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 40vw;
        }
        .Vid:hover
        {
            scale: 2.25;
        }
        
        .Desc
        {
            font-size: 1.5vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }
    
    }
    @media(min-aspect-ratio: 8/10) and (max-aspect-ratio: 9 /10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 40vw;
        }
        .Vid:hover
        {
            scale: 2.25;
        }
        
        .Desc
        {
            font-size: 1.5vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }

        .VidMargin
        {
            margin-top: 2vh;
        }
    
    }
    @media(min-aspect-ratio: 7/10) and (max-aspect-ratio: 8 /10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 70vw;
        }
        .Vid:hover
        {
            scale: 1.4;
        }
        
        .Desc
        {
            font-size: 1.65vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }

        .VidMargin
        {
            margin-top: 2vh;
            flex-direction: column;
        }
    
    }

    @media(min-aspect-ratio: 6/10) and (max-aspect-ratio: 7 /10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 70vw;
        }
        .Vid:hover
        {
            scale: 1.4;
        }
        
        .Desc
        {
            font-size: 1.5vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }

        .VidMargin
        {
            margin-top: 2vh;
            flex-direction: column;
        }
    
    }
    
    @media(min-aspect-ratio: 5/10) and (max-aspect-ratio: 6 /10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 70vw;
        }
        .Vid:hover
        {
            scale: 1.4;
        }
        
        .Desc
        {
            font-size: 1.5vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }

        .VidMargin
        {
            margin-top: 2vh;
            flex-direction: column;
        }
    
    }
    @media(min-aspect-ratio: 0/10) and (max-aspect-ratio: 5 /10)
    {
        .FullContainer
        {
            margin-top: 135vh;
        }
        .Text2
        {
            font-size: 2vmax;
            width: 70vw;
        }
        .Vid
        {
            width: 70vw;
        }
        .Vid:hover
        {
            scale: 1.4;
        }
        
        .Desc
        {
            font-size: 1.5vmax;
            color: white;
            margin-left: 2vw;
            margin-top: -1vh;
        }
        
        .DescTitle
        {
            font-size: 2vmax;
            color: yellow;
            margin-left: 2vw;
        }

        .VidMargin
        {
            margin-top: 2vh;
            flex-direction: column;
        }
    
    }
}