.Tilt
{
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    cursor: grab;
    transform: TranslateZ(60px);
}

.CardContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ImgStyle
{
    width: 12vw;
}

.Row
{
    margin-top: -12vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3vmax;
    justify-content: center;
}

.SectionContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5vw;
    width: 100vw;
    height: 100vh;
    position: absolute;
    margin-top: 360vh;
    z-index: 100;
}

.Text
{
    font-size: 2vw;
    color: white;
    margin-left: 0.5vw;
}

@media(orientation: portrait)
{
    .Text
    {
        font-size: 4vw;
        color: white;
        margin-left: 0.5vw;
    }
    .ImgStyle
    {
        width: 20vw;
    }
    .Row
    {
        margin-top: -5vh;
    }

    .SectionContainer
    {
        margin-top: 350vh;
    }
}