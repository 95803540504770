.glowingButton
{
    transform: translate(-0%, 0%);
    text-align: center;
    padding: 0px;
    padding-left: 2vw;
    padding-right: 2vw;
    line-height:  6vh;
    color: #bababa;
    background: none;
    font-size: 1.5vh;
    text-transform: uppercase;
    text-decoration: none;
    font-style: "Source Sans Pro, sans-serif";
    box-sizing: border-box;
    background-size: 0%;
    background-size: 400%;
    backdrop-filter: blur(30px);
    border-radius: 5vw;
    border: 0.1vh solid #8a8a8a;
}

.button7 {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }

.glowingButton:hover{
    animation: animate 8s linear infinite;
    cursor: pointer;
    color: transparent;
}
@keyframes animate
{
    0%
    {
        background-position: 0%;
    }
    100%
    {
        background-position: 400%;
    }
}

.glowingButton::before
{
    content: '';
    text-align: center;
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(90deg, #03a9f4, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 30px;
    opacity: 0;
    transition: 0.5s;
}
.glowingButton:hover:before
{
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
}