@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400&display=swap');

.LandingContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute; 
    width: 100vw;
}

.glass
{
    /* From https://css.glass */
    border-radius: 2px;
    opacity: .6;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.glass2
{
    /* From https://css.glass */
    border-radius: 2px;
    opacity: .6;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.glass3
{
    /* From https://css.glass */
    border-radius: 2px;
    opacity: .6;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Header
{
    margin-top: 11vmax;  
    font-size: 7vmax;
    color: white;
    text-align: center;
    opacity: 0;
}

.Subtitle
{
    text-align: center;
    font-size: 2vmax;
    color: #d9d9d9;
    margin-top: -3vmax;
    opacity: 0;
}

@media(orientation: landscape)
{

    @media(min-aspect-ratio: 14/10) and (max-aspect-ratio: 17/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 8.5vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 2.25vmax;
            color: #d9d9d9;
            margin-top: -3vmax;
        }
    }
    @media(min-aspect-ratio: 10.8/10) and (max-aspect-ratio: 13.999/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 9.5vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 2.75vmax;
            color: #d9d9d9;
            margin-top: -3vmax;
        }
    }

    @media(max-aspect-ratio: 10.799/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 10vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 3vmax;
            color: #d9d9d9;
            margin-top: -3vmax;
            width: 80%;
        }

        .Subtitle
        {
            color: #d9d9d9;
        }
    }
}

@media(orientation: portrait)
{
    .Subtitle
    {
        color: #d9d9d9;
    }

    @media(min-aspect-ratio: 9/10)
    {
        .Header
        {
            margin-top: 25vmax;  
            font-size: 11vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 3.5vmax;
            margin-top: -3vmax;
            width: 80%;
        }
    }
    @media(min-aspect-ratio: 7.5/10) and (max-aspect-ratio: 8.999/10)
    {

        .Header
        {
            margin-top: 25vmax;  
            font-size: 9.5vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 3vmax;
            margin-top: -3vmax;
            width: 80%;
        }
        
    }
    @media(min-aspect-ratio: 6.5/10) and (max-aspect-ratio: 7.49999/10)
    {
        .Header
        {
            margin-top: 25vmax;  
            font-size: 8vmax;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 2.5vmax;
            margin-top: -3vmax;
            width: 80%;
        }
    }
    @media(min-aspect-ratio: 5.5/10) and (max-aspect-ratio: 6.49999/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 10vmax;
            width: 90%;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 4vmax;
            margin-top: -2vmax;
            width: 90%;
        }
    }
    @media(max-aspect-ratio: 5.5/10) and (min-aspect-ratio: 4.5/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 9.5vmax;
            width: 90%;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 3.25vmax;
            margin-top: -2vmax;
            width: 90%;
        }
    }
    @media(min-aspect-ratio: 3.5/10) and (max-aspect-ratio: 4.49999/10)
    {
        .Header
        {
            margin-top: 15vmax;  
            font-size: 9vmax;
            width: 90%;
            color: white;
        }
    
        .Subtitle
        {
            font-size: 3vmax;
            margin-top: -2vmax;
            width: 90%;
        }
    }
}